<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Price List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          to="/inventory/new-price-list"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New Price List
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="priceList"
              :search="search"
            >
              <template v-slot:item.ValidFor="{ item }">
                <span v-if="item.ValidFor == 'Y'">Yes</span>
                <span v-else>No</span>
              </template>

              <template v-slot:item.ListName="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/inventory/view-price-list/${item.id}`"
                >{{ item.ListName }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    priceList: [],
    headers: [
      { text: "Name", value: "ListName" },
      { text: "Active", value: "ValidFor" },
      { text: "Primary Currency", value: "PrimCurr" },
      // { text: "Addational Currency 1", value: "addcurr1.currency" },
      // { text: "Addational Currency 2", value: "addcurr1.currency" },
      { text: "Base Price List", value: "basenum.ListName" },
      { text: "Default Factor", value: "Factor" },
    ],
  }),
  methods: {
    getPriceList() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.priceList = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getPriceList();
  },
};
</script>